import db from "./_index";

export async function getLiveClassesCategories() {
    return await db.ref("/lcCategoryList").once("value");
}

export async function getLiveClassesList(id: string = "") {
    return await db
        .ref(`/lcList/english/`)

        // .limitToFirst(limit)
        .once("value");
}


export async function getLiveClassParentList() {
    return await db
        .ref(`/lpList/english/`)
        .once("value");
}

export async function getLcListPerTeacher(teacherEmail: string) {
    return await db
        .ref(`/lcRcList/english/`)
        .orderByChild("teacherEmail")
        .equalTo(teacherEmail)
        .once("value");
}


export async function getRcList(lpId: string = "", limit: number) {
    return await db
        .ref(`/lcRcList/english/`)
        .orderByChild("lpId")
        .equalTo(`${lpId}`)
        .limitToFirst(limit)
        .once("value");
}


export async function getRcListAll(lpId: string = "") {
    return await db
        .ref(`/lcRcList/english/`)
        .orderByChild("lpId")
        .equalTo(`${lpId}`)
        .once("value");
}

export async function getLiveClassesListPerTeacher(id: string = "") {
    return await db
        .ref(`/lcRcList/english/`)
        .orderByChild("teacherId")
        .equalTo(`${id}`)
        .once("value");
}

export async function getLiveClassBookings(lcId: string) {
    return await db
        .ref(`/lcBookings/english/`)
        .orderByChild("lcId")
        .equalTo(`${lcId}`)
        .once("value");
}

export async function getLiveClassDetails(id: string) {
    return await db.ref(`/lcDetails/english/${id}`).once("value");
}

export async function getLiveClassSlots(id: string) {
    return await db.ref(`/lcMeta/${id}/slotsAvailable`).once("value");
}


export async function getLcMeetings(lcId: string) {
    return await db
        .ref("lcMeetings/english/" + lcId)
        .once("value");
}

export async function getLcRcList(lcId: string | null) {
    return await db
        .ref("lcRcList/english/" + lcId)
        .once("value");
}

export async function getLcDetailsHtml(lcId: string | null) {
    return await db
        .ref("lcDetailsHtml/english/" + lcId)
        .once("value");
}

export async function getUserDetails(userId: string | null) {
    return await db
        .ref("users/" + userId)
        .once("value");
}
