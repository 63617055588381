import React from 'react'
import {
    Avatar,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    createStyles,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core'
import {Sms, ViewHeadline} from "@material-ui/icons";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        withSpacing: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        root: {
            flexGrow: 1,
            width: '100%',

            backgroundColor: theme.palette.background.paper,
        },
        inline: {
            display: 'inline',
        },
        form: {
            '& > *': {
                margin: theme.spacing(2),
                minWidth: '25ch',
            },


        },

        formControl: {
            margin: theme.spacing(1),
            minWidth: 200,
        },
    }),
);

interface Props {
}

const Messages = () => {
    const classes = useStyles();

    // console.log(role);


    return <Card>


        <CardHeader
            title={"MESSAGES"}
            avatar={<Sms color={"primary"}/>}
        >
        </CardHeader>
        <Divider/>
        <CardContent>

            <List className={classes.root}>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg"/>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Brunch this weekend?"
                        secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                    Ali Connors
                                </Typography>
                                {" — I'll be in your neighborhood doing errands this…"}
                            </React.Fragment>
                        }
                    />
                </ListItem>
                <Divider variant="inset" component="li"/>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg"/>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Summer BBQ"
                        secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                    to Scott, Alex, Jennifer
                                </Typography>
                                {" — Wish I could come, but I'm out of town this…"}
                            </React.Fragment>
                        }
                    />
                </ListItem>
                <Divider variant="inset" component="li"/>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg"/>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Oui Oui"
                        secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                    Sandra Adams
                                </Typography>
                                {' — Do you have Paris recommendations? Have you ever…'}
                            </React.Fragment>
                        }
                    />
                </ListItem>
            </List>

        </CardContent>

        <CardActions className={`${classes.withSpacing}`}>


            <Link to="/live-classes"> <Button startIcon={<ViewHeadline/>} variant="contained"
                                              color="default">View All</Button></Link>

        </CardActions>
    </Card>
}

export default Messages