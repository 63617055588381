import React, {useContext} from 'react'
import {
    Card,
    CardContent,
    CardHeader,
    createStyles,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Theme
} from '@material-ui/core'
import {Link} from "react-router-dom";
import {Accessibility, Help, QuestionAnswer, Report} from "@material-ui/icons";
import {LocalContext} from "../../../services/fbAuth";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        withSpacing: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        root: {
            flexGrow: 1,

        },
        form: {
            '& > *': {
                margin: theme.spacing(2),
                minWidth: '25ch',
            },


        },

        formControl: {
            margin: theme.spacing(1),
            minWidth: 200,
        },
    }),
);

interface Props {
}

const HomeExtraSidebar = () => {
    const classes = useStyles();
    const {role} = useContext(LocalContext);

    // console.log(role);


    return <Card className={"card"}>

        <CardHeader
            title={"Helper"} avatar={<Help color={"primary"}/>}>
        </CardHeader>
        <Divider/>
        <CardContent>


            <List component="nav" className={classes.root} aria-label="List">
                <Link to={""}>
                    <ListItem button>
                        <ListItemIcon>
                            <QuestionAnswer/>
                        </ListItemIcon>
                        <ListItemText primary="FAQ"
                                      secondary={"Common FAQ for Teacher, Admin"}/>
                    </ListItem>
                </Link>
                <Divider/> <Link to={""}>
                <ListItem button>
                    <ListItemIcon>
                        <QuestionAnswer/>
                    </ListItemIcon>
                    <ListItemText primary="Documentation"
                                  secondary={"Training Manual, Documentation, Help, Hints"}/>
                </ListItem>
            </Link>
                <Divider/> <Link to={""}>
                <ListItem button>
                    <ListItemIcon>
                        <Report/>
                    </ListItemIcon>
                    <ListItemText primary="Report a Bug/Issues"
                                  secondary={"Having trouble? while using admin app. Ask here."}/>
                </ListItem>
            </Link>
                <Divider/> <Link to={""}>
                <ListItem button>
                    <ListItemIcon>
                        <Accessibility/>
                    </ListItemIcon>
                    <ListItemText primary="Feature Request"
                                  secondary={"Submit any feature/enhancement request"}/>
                </ListItem>
            </Link>
                <Divider/> <Link to={""}>
                <ListItem button>
                    <ListItemIcon>
                        <QuestionAnswer/>
                    </ListItemIcon>
                    <ListItemText primary="Report a bug" secondary={"Report any bug related to admin app/class/"}/>
                </ListItem>
            </Link>


            </List>


        </CardContent>

    </Card>
}

export default HomeExtraSidebar