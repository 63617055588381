import React, {FC, useEffect} from 'react'
import {useHistory} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles({
    paper: {
        minWidth: 275,
        margin: '1rem',
        padding: 20,
        backgroundColor: '#F8BE15'


    },


});

const AuthContainer: FC = ({children}) => {
    const classes = useStyles();

    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        // show user being redirected
        if (history.action === 'REPLACE') {
            enqueueSnackbar('Please kindly log in first');
        }
    }, [enqueueSnackbar, history.action])


    return (


        <div>
            {children}
        </div>

    )
}

export default AuthContainer
