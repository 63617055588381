import React, {FC, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import {Avatar, Button, CardActions, CardHeader, CircularProgress, Grid} from '@material-ui/core';
import {Link, useRouteMatch} from 'react-router-dom';
import {getLiveClassParentList} from '../../../services/db/liveclasses';
import {LiveClassParent} from '../../../services/interfaces';
import Container from "../../../containers/Container";
import ViewListIcon from '@material-ui/icons/ViewList';


const useStyles = makeStyles({

    withSpacing: {
        '& > *': {
            margin: 1,
        },
    },
    root: {
        minWidth: 275,
    },

    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});


// interface Props {
//     path: string
// }

const LpListCardView: FC = () => {


    const {path, url} = useRouteMatch();

    const classes = useStyles();
    const [lcSnap, setLcSnap] = useState<LiveClassParent[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const callLiveClassesList = async () => {
            // call firebase
            //   let lcSnapFb = await getLiveClassesListPerTeacher("lt0Isk6bnkGPMbwCBuiNbw");

            let lcSnapFb = await getLiveClassParentList();


            let lcSnapFbArray: LiveClassParent[] = [];
            let optionsArray = [];

            lcSnapFb?.forEach(item => {
                lcSnapFbArray.push(item.val())


            })


            setLcSnap(lcSnapFbArray);


            setLoading(false);
        }


        callLiveClassesList()

        return () => setLcSnap([])
    }, [])


    return <Container headerTitle={"List"}>

        <Grid container spacing={2} justify="center" alignItems="center" direction="column"
        >


            {loading ? <CircularProgress color="secondary"/> : ""}
        </Grid>
        <Grid container spacing={2}>


            {lcSnap ? lcSnap.map((item) => (
                <Grid key={item.lpId} item xs={12} md={6}>


                    <Card key={item.lpId} className={classes.root}>

                        <CardHeader
                            avatar={
                                <Avatar src={item.lpImage} aria-label={item.lpTitle}>
                                    S
                                </Avatar>
                            }

                            title={item.lpTitle}
                            subheader={item.lpDesc}
                        />


                        <CardActions className={classes.withSpacing} disableSpacing key={item.lpId}
                                     style={{marginLeft: 'auto', float: 'right'}}>


                            <Link key={item.lpId} to={`/live-classes/${item.lpId}`}>
                                <Button variant="text"
                                        color="secondary"
                                        size={"small"}
                                        startIcon={<ViewListIcon/>}
                                >Recurrence</Button></Link>

                        </CardActions>

                    </Card>

                </Grid>
            )) : "loading"}


        </Grid>
    </Container>
}

export default LpListCardView