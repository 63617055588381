import React, { FC } from 'react'
import AuthHeader from '../../components/AuthHeader';
import Form from './components/Form';
import AuthContainer from '../../layouts/AuthContainer';


const Login: FC = () => {

  return (
    <AuthContainer>
          <Form/>
    </AuthContainer>
  )
}

export default Login
