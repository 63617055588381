import React from 'react'
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    createStyles,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Theme
} from '@material-ui/core'
import {Notifications, RemoveRedEye, ViewHeadline} from "@material-ui/icons";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        withSpacing: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        root: {
            flexGrow: 1,

        },
        form: {
            '& > *': {
                margin: theme.spacing(2),
                minWidth: '25ch',
            },


        },

        formControl: {
            margin: theme.spacing(1),
            minWidth: 200,
        },
    }),
);

interface Props {
}

const NoticeBoard = () => {
    const classes = useStyles();

    // get general notification and teachers notification

    //get general notification

    //get teacher's notifications

    return <Card>


        <CardHeader
            title={"Notifications"}
            avatar={<Notifications color={"primary"}/>}
        >
        </CardHeader>
        <Divider/>
        <CardContent>

            <List dense>

                <ListItem>
                    <ListItemText
                        primary="Single-line item lskjf slfjs lfjsd lfkjs dfslfksjd l"
                        secondary="July 20, 2014"
                    />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete">
                            <RemoveRedEye/>
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>

            </List>
        </CardContent>

        <CardActions className={`${classes.withSpacing}`}>


            <Link to="#"> <Button startIcon={<ViewHeadline/>} variant="contained"
                                  color="default">View All</Button></Link>

        </CardActions>
    </Card>
}

export default NoticeBoard