import React, {FC, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Grid, Paper} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {getLiveClassParentList} from '../../../services/db/liveclasses';
import {LcParentWithId} from '../../../services/interfaces';
import Container from "../../../containers/Container";
import ViewListIcon from '@material-ui/icons/ViewList';
import {GridApi, GridCellValue, GridColDef} from "@material-ui/data-grid";
import LpListDataTable from "../../../datatables/LpListDataTable";
import SimpleBackdrop from "../../../components/UiElements/BackDrop";
import AlertDialog from "../../../components/UiElements/AlertDialog";
import {Visibility} from "@material-ui/icons";

const useStyles = makeStyles({

    withSpacing: {
        '& > *': {
            margin: 1,
        },
    },
    root: {
        minWidth: 275,
    },

    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});


// interface Props {
//     path: string
// }

const LpList: FC = () => {


    const history = useHistory();
    const classes = useStyles();
    const [lcSnap, setLcSnap] = useState<LcParentWithId[]>([]);
    const [loading, setLoading] = useState(true);
    const [singleRow, setSingleRow] = useState([] as any);
    const [showAlert, setShowAlert] = useState(false);

    const columnsData: GridColDef[] = [
        {field: 'lpTitle', headerName: 'Title', width: 300},
        {
            field: "info",
            headerName: "Info",
            sortable: false,
            width: 100,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClick = () => {
                    const api: GridApi = params.api;
                    const fields = api
                        .getAllColumns()
                        .map((c) => c.field)
                        .filter((c) => c !== "__check__" && !!c);
                    const thisRow: Record<string, GridCellValue> = {};

                    fields.forEach((f) => {
                        thisRow[f] = params.getValue(f);
                    });
                    setSingleRow(thisRow);


                    return setShowAlert(true)

                    // return <AlertDialog row={thisRow} openProps={true}/>

                    // return alert(JSON.stringify(thisRow, null, 4));
                };

                return <Button startIcon={<Visibility/>} onClick={onClick} variant={"text"} color={"secondary"}
                               size={"small"}/>;
            }
        },

        {
            field: "rec",
            headerName: "Recurrence",
            sortable: false,
            width: 100,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClick = () => {
                    const api: GridApi = params.api;
                    const fields = api
                        .getAllColumns()
                        .map((c) => c.field)
                        .filter((c) => c !== "__check__" && !!c);
                    const thisRow: Record<string, GridCellValue> = {};

                    fields.forEach((f) => {
                        thisRow[f] = params.getValue(f);
                    });
                    const link = `/live-classes/${thisRow.id}`;

                    return history.push(link)
                };

                return <Button startIcon={<ViewListIcon/>} onClick={onClick} variant={"text"} color={"primary"}
                               size={"small"}/>;
            }
        },


        {field: 'lpCategory', headerName: 'Category', width: 300},
        {field: 'id', headerName: 'ID', width: 300},
        {field: 'lpCategoryId', headerName: 'Category Id', width: 300},
        {field: 'lpDesc', headerName: 'Description', width: 300},
        {field: 'lcIndex', headerName: 'lcIndex', type: "number", width: 70},
        {field: 'lpIndexAsc', headerName: 'lpIndexAsc', type: "number", width: 70},
        {field: 'lpImage', headerName: 'Image', width: 300},
        {field: 'lpStatus', headerName: 'Status', width: 300},


    ];

    useEffect(() => {
        const callLiveClassesList = async () => {
            // call firebase
            //   let lcSnapFb = await getLiveClassesListPerTeacher("lt0Isk6bnkGPMbwCBuiNbw");
            let lcSnapFb = await getLiveClassParentList();

            let lcSnapFbArray: LcParentWithId[] = [];
            let optionsArray = [];

            lcSnapFb?.forEach(item => {
                let obj = Object.assign(item.val(), {"id": item.key});
                lcSnapFbArray.push(obj)


            })


            setLcSnap(lcSnapFbArray);


            setLoading(false);
        }


        callLiveClassesList()

        return () => setLcSnap([])
    }, [])

    const handleClose = () => {
        setShowAlert(false)

    }


    return <Container headerTitle={"Live Class List"}>

        <Grid container spacing={2} justify="center" alignItems="center" direction="column"
        >

            {showAlert ? <AlertDialog handleCloseProps={handleClose} row={singleRow}
                                      openProps={showAlert}/> : ""}

            {loading ? <SimpleBackdrop openProps={loading}/> : ""}
        </Grid>


        <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
                <Paper variant={"outlined"}>
                    <LpListDataTable rowsData={lcSnap} columnsData={columnsData}/>
                </Paper>
            </Grid>

        </Grid>


    </Container>
}

export default LpList