import React, {FC} from 'react'
import {useParams} from 'react-router-dom';
import Container from '../../../containers/Container';
import {Grid} from "@material-ui/core";
import ClassInfo from "./components/ClassInfo";
import SingleClassEmail from "./components/SingleClassEmail";
import SingleClassMaterial from "./components/SingleClassMaterial";
import SingleClassMessages from "./components/SingleClassMessages";
import SingleClassNotes from "./components/SingleClassNotes";
import SingleClassActions from "./components/SingleClassActions";


interface Props {
    lpId: string
    lcId: string
}

const LcSingleView: FC = () => {
    // const {lpId, lcId} = useRouteMatch();
    const {lpId, lcId} = useParams<Props>();
    return <Container headerTitle={"Live Class"}>
        <Grid container spacing={1}>

            <Grid item xs={12} md={8}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>

                        <ClassInfo/>
                    </Grid>
                    <Grid item xs={12} md={12}>

                        <SingleClassEmail/>

                    </Grid>
                    <Grid item xs={12} md={12}>

                        <SingleClassMaterial/>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={12} md={4}>
                <Grid container spacing={1}>

                    <Grid item xs={12} md={12}>

                        <SingleClassActions/>

                    </Grid>
                    <Grid item xs={12} md={12}>

                        <SingleClassMessages/>

                    </Grid>
                    <Grid item xs={12} md={12}>
                        <SingleClassNotes/>

                    </Grid>

                </Grid>

            </Grid>

        </Grid>
    </Container>
}

export default LcSingleView