import React, {FC, useEffect, useState} from 'react'
import {Grid, IconButton, LinearProgress, Paper, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import Moment from "react-moment";
import {
    getLiveClassCount,
    getLiveClassesReports,
    getRegistrationsCount,
    getUserCount,
    updateLiveClassCount,
    updateRegistrationCount,
    updateUserCount
} from '../../../services/db/reports';
import {reportSummary} from "../../../services/interfaces";
import {Refresh} from "@material-ui/icons";
import moment from "moment";


const HomeReports: FC = () => {


    const initialState = {
        "liveClasses": {
            "count": 0,
            "dateTs": moment.now()
        },
        "registrations": {
            "count": 0,
            "dateTs": moment.now()
        },
        "users": {
            "count": 0,
            "dateTs": moment.now()
        }
    }

    const [liveClassReport, setLiveClassReport] = useState<reportSummary>(initialState);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const callFb = async () => {
            let lcSnapFb = await getLiveClassesReports();

            console.log(lcSnapFb.val())
            setLiveClassReport(lcSnapFb.val());
            setLoading(false)

        }


        callFb()

    }, [loading])


    async function getLcReports() {
        setLoading(true)
        // get live classes and update the node
        let liveClassCount = await getLiveClassCount();
        const liveClassData = {
            count: liveClassCount.numChildren(),
            dateTs: moment.now()
        }
        await updateLiveClassCount(liveClassData).then(() => setLoading(false))

    }

    async function getUsers() {
        // count all registered users
        setLoading(true)
        let userCount = await getUserCount();
        const liveClassData = {
            count: userCount.numChildren(),
            dateTs: moment.now()
        }
        await updateUserCount(liveClassData).then(() => setLoading(false))
    }

    async function getRegistrations() {
        setLoading(true)
        // get total registrations from lcBookings
        let registrationCount = await getRegistrationsCount();
        const liveClassData = {
            count: registrationCount.numChildren(),
            dateTs: moment.now()
        }
        await updateRegistrationCount(liveClassData).then(() => setLoading(false))

    }

    return <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    {loading && <LinearProgress/>}
                </Grid>

            </Grid>
            <Grid container spacing={1}>


                <Grid item xs={12} md={4} lg={4}>
                    <Paper className={"pricePaper bgBlue"}

                    >
                        <Typography component="p" variant="caption">
                            Users
                        </Typography>

                        <Grid container justify={"center"} spacing={1}>
                            <Grid item>
                                <Typography component="p" variant="h4">

                                    {liveClassReport.users.count}
                                </Typography>

                            </Grid>
                            <Grid item>
                                <Typography>
                                    on <Moment
                                    format="LL">{liveClassReport.users.dateTs}</Moment>
                                </Typography>
                                <Link to="/coming-soon">
                                    More Information
                                </Link>

                            </Grid>
                            <Grid item>
                                <IconButton

                                    onClick={getUsers}
                                >
                                    <Refresh/>
                                </IconButton>
                            </Grid>

                        </Grid>


                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Paper className={"pricePaper bgGrey"}

                    >
                        <Typography component="p" variant="caption">
                            Live Classes
                        </Typography>
                        <Grid container justify={"center"} spacing={1}>
                            <Grid item>
                                <Typography component="p" variant="h4">

                                    {liveClassReport.liveClasses.count}
                                </Typography>

                            </Grid>
                            <Grid item>
                                <Typography>
                                    on <Moment
                                    format="LL">{liveClassReport.liveClasses.dateTs}</Moment>
                                </Typography>
                                <Link to="/coming-soon">
                                    More Information
                                </Link>

                            </Grid>
                            <Grid item>
                                <IconButton

                                    onClick={getLcReports}
                                >
                                    <Refresh/>
                                </IconButton>
                            </Grid>

                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                    <Paper className={"pricePaper bgTeal"}

                    >
                        <Typography component="p" variant="caption">
                            Registrations
                        </Typography>
                        <Grid container justify={"center"} spacing={1}>
                            <Grid item>
                                <Typography component="p" variant="h4">

                                    {liveClassReport.registrations.count}
                                </Typography>

                            </Grid>
                            <Grid item>
                                <Typography>
                                    on <Moment
                                    format="LL">{liveClassReport.registrations.dateTs}</Moment>
                                </Typography>
                                <Link to="/coming-soon">
                                    More Information
                                </Link>

                            </Grid>
                            <Grid item>
                                <IconButton

                                    onClick={getRegistrations}
                                >
                                    <Refresh/>
                                </IconButton>
                            </Grid>

                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>


    </Grid>


}

export default HomeReports