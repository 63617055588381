import * as React from 'react';
import {DataGrid, GridColDef, GridToolbar, GridValueGetterParams} from '@material-ui/data-grid';
import {FC} from "react";
import {LiveClassTable} from "../services/interfaces";


interface Props {
    rowsData: LiveClassTable[],
    columnsData: GridColDef[],
}

const DataTable: FC<Props> = ({rowsData, columnsData}) => {
    return (
        <div style={{height: 400, width: '100%'}}>

            <DataGrid
                rows={rowsData}
                columns={columnsData}
                density={"compact"}
                components={{
                    Toolbar: GridToolbar,

                }}
                checkboxSelection={true}
                rowsPerPageOptions={[100, 250]}

            />
        </div>
    );
}
export default DataTable;