import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {Link} from 'react-router-dom';


const ITEM_HEIGHT = 48;

type Props = {
    lcId: string
    lpId: string
}

export default function LongMenu(props: Props) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const options = [
        {
            title: "View Class",
            link: `/live-classes/${props.lpId}/${props.lcId}/view`
        },
        {
            title: "Edit Class",
            link: `/live-classes/${props.lpId}/${props.lcId}`
        },
        {
            title: "Get Class Code",
            link: `/live-classes/${props.lpId}/${props.lcId}`
        },

    ];

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {options.map((option, index) => (
                    <Link to={option.link} key={index}>
                        <MenuItem onClick={handleClose}>
                            {option.title}
                        </MenuItem>
                    </Link>
                ))}
            </Menu>
        </div>
    );
}
