import React from 'react'
import {Switch} from 'react-router-dom';
import Home from './pages/Home';
import Reports from './pages/Reports';
import LcReportSingle from './pages/Reports/LcReportSingle';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import {useAuthState} from 'react-firebase-hooks/auth';
import fbAuth from './services/fbAuth';
import Login from './pages/Login';
import LcSingle from "./pages/LiveClasses/LcRecurrence/LcSingleView";
import LcList from "./pages/LiveClasses/LcRecurrence/LcList";
import LcSingleEdit from "./pages/LiveClasses/LcRecurrence/LcSingleEdit";
import LpList from "./pages/LiveClasses/LcParent/LpList";
import LcListCardView from "./pages/LiveClasses/LcRecurrence/LcListCardView";
import Users from "./pages/Users";
import UserSingle from "./pages/Users/layouts/UserSingle";
import ComingSoon from "./pages/Others/ComingSoon";
import LpListCardView from "./pages/LiveClasses/LcParent/LpListCardView";

interface Props {
}

const Routes = () => {

    const [user] = useAuthState(fbAuth);
    const authenticated = Boolean(user);

    return <Switch>

        {/* admin */}
        <PrivateRoute exact path="/" component={Home} authenticated={authenticated}/>
        {/*live classes */}
        <PrivateRoute exact path="/live-classes" component={LpList} authenticated={authenticated}/>
        <PrivateRoute exact path="/live-classes/cards" component={LcListCardView} authenticated={authenticated}/>
        <PrivateRoute exact path="/live-classes/:lpId" component={LcList} authenticated={authenticated}/>
        <PrivateRoute exact path="/live-classes/:lpId/cards" component={LpListCardView} authenticated={authenticated}/>
        <PrivateRoute exact path="/live-classes/:lpId/:lcId/table" component={LcSingle} authenticated={authenticated}/>
        <PrivateRoute exact path="/live-classes/:lpId/:lcId/cards" component={LcListCardView}
                      authenticated={authenticated}/>
        <PrivateRoute exact path="/live-classes/:lpId/:lcId/view" component={LcSingle} authenticated={authenticated}/>
        <PrivateRoute exact path="/live-classes/:lpId/:lcId/report" component={LcReportSingle}
                      authenticated={authenticated}/>
        <PrivateRoute exact path="/live-classes/:lpId/:lcId/create" component={LcSingleEdit}
                      authenticated={authenticated}/>

        {/*live classes teacher*/}


        {/*comming soon*/}
        <PrivateRoute exact path="/coming-soon" component={ComingSoon} authenticated={authenticated}/>

        {/*reports*/}
        <PrivateRoute exact path="/reports" component={ComingSoon} authenticated={authenticated}/>
        <PrivateRoute exact path="/reports/:lpId/:lcId" component={ComingSoon} authenticated={authenticated}/>
        <PrivateRoute exact path="/reports/:lpId" component={ComingSoon} authenticated={authenticated}/>

        {/*forms*/}
        <PrivateRoute exact path="/forms" component={Reports} authenticated={authenticated}/>
        <PrivateRoute exact path="/forms/:formId" component={LcReportSingle} authenticated={authenticated}/>

        {/*certificates*/}
        <PrivateRoute exact path="/certificates" component={ComingSoon} authenticated={authenticated}/>
        <PrivateRoute exact path="/certificates/:certificateId" component={ComingSoon}
                      authenticated={authenticated}/>

        {/*emails*/}

        {/*Users*/}
        <PrivateRoute exact path="/users" component={Users} authenticated={authenticated}/>
        <PrivateRoute exact path="/users/:userId" component={UserSingle} authenticated={authenticated}/>


        {/*Teachers*/}
        <PrivateRoute exact path="/teachers" component={Reports} authenticated={authenticated}/>
        <PrivateRoute exact path="/teachers/:teacherId" component={ComingSoon} authenticated={authenticated}/>

        {/*Programs*/}
        <PrivateRoute exact path="/programs" component={Reports} authenticated={authenticated}/>
        <PrivateRoute exact path="/programs/:programId" component={ComingSoon} authenticated={authenticated}/>


        {/*Support*/}
        <PrivateRoute exact path="/supports" component={Reports} authenticated={authenticated}/>
        <PrivateRoute exact path="/supports/:supportId" component={ComingSoon} authenticated={authenticated}/>


        {/*Student Group*/}


        {/* authentication */}
        <PublicRoute exact path="/login" component={Login} authenticated={authenticated}/>


    </Switch>
}

export default Routes


