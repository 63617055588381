import React, {FC, useEffect, useState} from 'react'
import {LcBookingData} from "../../services/interfaces";
import {getLcBookings} from "../../services/db/bookings";
import UserDataTable from "../../datatables/UserDataTable";
import {GridColDef} from "@material-ui/data-grid";


interface Props {
}

const LcReportSummary: FC = () => {
    // const {path, url} = useRouteMatch();
    // console.log(path);
    const [finalBookingData, setFinalBookingData] = useState<LcBookingData[]>([]);

    let lcSnapFbArray: LcBookingData[] = [];
    const columnsData: GridColDef[] = [
        {field: 'id', headerName: 'ID', width: 70},
        {field: 'userId', headerName: 'userId', width: 70},
        {field: 'lcId', headerName: 'lcId', width: 70},
        {field: 'bookingTs', headerName: 'bookingTs', type: 'dateTime', width: 200},
        {field: 'userName', headerName: 'userName', width: 200},
        {field: 'userEmail', headerName: 'userEmail', width: 200},
        {field: 'teacherId', headerName: 'teacherId', width: 200},
        {field: 'lcCategoryId', headerName: 'lcCategoryId', width: 200},
        {field: 'lcCourseId', headerName: 'lcCourseId', width: 200},
        {field: 'lcEndDateTs', headerName: 'lcEndDateTs', type: 'dateTime', width: 200},
        {field: 'lcLevelId', headerName: 'lcLevelId', width: 200},
        {field: 'lcProgramId', headerName: 'lcProgramId', width: 200},
        {field: 'lcStartDateTs', headerName: 'lcStartDateTs', type: 'dateTime', width: 200},
        {field: 'lcSupportId', headerName: 'lcSupportId', width: 200},
        {field: 'lcTargetLang', headerName: 'lcTargetLang', width: 200},
        {field: 'lpId', headerName: 'lpId', width: 200},
        {field: 'teacherName', headerName: 'teacherName', width: 200}


    ];
    useEffect(() => {


            const callAllBookings = async () => {
                // let combinedDetails: LiveClassBookings = {} as LiveClassBookings


                // call firebase
                const userBookingAll = await getLcBookings();

                userBookingAll.forEach(item => {
                    //
                    let obj = Object.assign(item.val(), {"id": item.key});
                    lcSnapFbArray.push(obj);
                    console.log(item.key)
                })

                // userBookingAll.forEach(userIdItem => {
                //     // console.log(userIdItem)
                //     let userId = userIdItem.key || "NA";
                //     // userIdItem.val().userId = userIdItem.key;
                //     // console.log(userIdItem.val())
                //
                //
                //     // lcSnapFbArray.push(userIdItem.val())
                //
                //     // let userDetails = getUserDetails(userIdItem.key);
                //
                //
                //     // userIdItem.forEach(classIdItem => {
                //     //
                //     //     const callLcRcList = async () => {
                //     //         const lcRcListObj = await getLcRcList(classIdItem.key);
                //     //
                //     //         const teacherName = lcRcListObj.val().teacherName || "Sols Teacher";
                //     //         const teacherId = lcRcListObj.val().teacherId || "NA";
                //     //         const lcCategoryId = lcRcListObj.val().lcCategoryId || "NA";
                //     //         const lcCourseId = lcRcListObj.val().lcCourseId || "NA";
                //     //         const lcLevelId = lcRcListObj.val().lcLevelId || "NA";
                //     //         const lcProgramId = lcRcListObj.val().lcProgramId || "NA";
                //     //         const lcSupportId = lcRcListObj.val().lcSupportId || "NA";
                //     //         const lcTargetLang = lcRcListObj.val().lcTargetLang || "NA";
                //     //         const lpId = lcRcListObj.val().lpId || "NA";
                //     //         const lcTitle = lcRcListObj.val().lcTitle || "Live Class";
                //     //         const lcStartDateTs = lcRcListObj.val().lcStartDateTs || moment().unix();
                //     //         const lcEndDateTs = lcRcListObj.val().lcEndDateTs || moment().unix();
                //     //
                //     //         const lcBookingsData: LcBookingData = {
                //     //             userId: userId,
                //     //             lcId: classIdItem.val().lcId,
                //     //             bookingTs: moment.now(),
                //     //             userName: classIdItem.val().userName,
                //     //             userEmail: classIdItem.val().userEmail,
                //     //             teacherId: teacherId,
                //     //             lcCategoryId: lcCategoryId,
                //     //             lcCourseId: lcCourseId,
                //     //             lcEndDateTs: lcEndDateTs,
                //     //             lcLevelId: lcLevelId,
                //     //             lcProgramId: lcProgramId,
                //     //             lcStartDateTs: lcStartDateTs,
                //     //             lcSupportId: lcSupportId,
                //     //             lcTargetLang: lcTargetLang,
                //     //             lpId: lpId,
                //     //             teacherName: teacherName,
                //     //         };
                //     //         lcSnapFbArray.push(lcBookingsData)
                //     //         // console.log(lcBookingsData)
                //     //     }
                //     //
                //     //     callLcRcList();
                //     //
                //     //     // console.log(item.key)
                //     //     // let obj = Object.assign(childItem.val(), {"userId": item.key});
                //     //     // obj = Object.assign(obj, {"userEmail2": childItem.val().userEmail});
                //     //     // obj = Object.assign(obj, {"l": childItem.val().userEmail});
                //     //
                //     //     // console.log(lcSnapFbArray)
                //     //
                //     // })
                //
                // })


                setFinalBookingData(lcSnapFbArray)

            }


            callAllBookings();

            return () => setFinalBookingData([])
        },
        []
    )


    return <div>

        <UserDataTable
            columnsData={columnsData}
            rowsData={finalBookingData}/>

    </div>


}

export default LcReportSummary