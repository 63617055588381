import React from 'react';
import Container from "../../containers/Container";

const ComingSoon = () => {
    return (
        <Container headerTitle={"Coming Soon"}>


            <h1>Coming Soon</h1>
        </Container>
    );
};

export default ComingSoon;
