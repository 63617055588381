import React, {FC, useContext} from 'react'
import Container from '../../containers/Container';
import {Grid} from '@material-ui/core';
import NoticeBoard from './components/NoticeBoard';
import Messages from "./components/Messages";
import HomeActions from "./components/HomeActions";
import {LocalContext} from "../../services/fbAuth";
import HomeReports from "./components/HomeReports";
import HomeAdminActions from "./components/HomeAdminActions";
import HomeExtraSidebar from "./components/HomeExtraSidebar";


const Home: FC = () => {

    const {role} = useContext(LocalContext);


    return <Container headerTitle={"Dashboard"}>
        {role > 0 && role < 16 ? <HomeReports/> : ""}
        <Grid container spacing={1}>
            <Grid item xs={12} md={5}>
                <Grid container spacing={1}>

                    <Grid item xs={12} md={12}>

                        <NoticeBoard/>

                    </Grid>
                    <Grid item xs={12} md={12}>

                        <Messages/>

                    </Grid>
                </Grid>

            </Grid>

            <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        {(role > 0 && role < 16) ? <HomeAdminActions/> : <HomeActions/>}

                    </Grid>
                    <Grid item xs={12} md={12}>


                    </Grid>
                    <Grid item xs={12} md={12}>


                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={12} md={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        <HomeExtraSidebar/>

                    </Grid>
                    <Grid item xs={12} md={12}>


                    </Grid>
                    <Grid item xs={12} md={12}>


                    </Grid>
                </Grid>

            </Grid>
        </Grid>


    </Container>


}

export default Home