import db from './_index';

export async function getLiveClassesReports() {
    return await db.ref('reports/reportSummary').once('value');
}


export async function getLiveClassCount() {
    return await db.ref('lcRcList/english').once('value');
}


export async function getUserCount() {
    return await db.ref('users').once('value');
}


export async function getRegistrationsCount() {
    return await db.ref('lcBookings/english').once('value');
}

export async function updateLiveClassCount(data: Object) {
    return await db.ref('reports/reportSummary/liveClasses').update(data);
}

export async function updateUserCount(data: Object) {
    return await db.ref('reports/reportSummary/users').update(data);

}

export async function updateRegistrationCount(data: Object) {
    return await db.ref('reports/reportSummary/registrations').update(data);

}
