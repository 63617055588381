import React, {useContext} from 'react'
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    createStyles,
    Divider,
    makeStyles,
    Tab,
    Tabs,
    Theme
} from '@material-ui/core'
import {Chat, LinkRounded} from "@material-ui/icons";
import {LocalContext} from "../../../../services/fbAuth";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        withSpacing: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        root: {
            flexGrow: 1,

        },
        form: {
            '& > *': {
                margin: theme.spacing(2),
                minWidth: '25ch',
            },


        },

        formControl: {
            margin: theme.spacing(1),
            minWidth: 200,
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1
        },
        iconButton: {
            padding: 10
        },
        inputPaper: {
            padding: "2px 4px",
            display: "flex",
            alignItems: "center",
            margin: "10px",

        },
    }),
);

interface Props {
}

const SingleClassMessages = () => {
    const classes = useStyles();
    const {role} = useContext(LocalContext);
    const [value, setValue] = React.useState(2);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };


    return <Card className={"card"}>

        <CardHeader
            title={"MESSAGES"} avatar={<Chat color={"primary"}/>}>
        </CardHeader>
        <Divider/>
        <CardContent>

            <Tabs
                value={0}
                indicatorColor="secondary"
                textColor="secondary"

                onChange={handleChange}
                aria-label="disabled tabs example"
            >
                <Tab label="Unread"/>
                <Tab label="Read"/>
            </Tabs>


        </CardContent>

        <CardActions className={`${classes.withSpacing}`}>

            {/*{customClaim < 10 &&*/}
            {role < 15 ? <Link to="/live-classes"> <Button startIcon={<LinkRounded/>} variant="contained"
                                                           color="default">View all</Button></Link> : ""}


        </CardActions>
    </Card>
}

export default SingleClassMessages