import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {Avatar, Button, CardHeader, Chip, CircularProgress, Divider, Grid} from '@material-ui/core';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import {getLcListPerTeacher} from '../../../services/db/liveclasses';
import {LcRcList} from '../../../services/interfaces';
import Container from "../../../containers/Container";
import Moment from "react-moment";
import LongMenu from "../../../components/UiElements/LongMenu";
import SimpleBackdrop from "../../../components/UiElements/BackDrop";
import moment from "moment";
import fbAuth from "../../../services/fbAuth";
import {ViewHeadlineRounded} from "@material-ui/icons";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        withSpacing: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        root: {
            minWidth: 275,
        },

        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
    }),
);


interface Props {
    lpId: string
}

const LcListCardView = () => {
    const classes = useStyles();
    const [lcSnap, setLcSnap] = useState<LcRcList[]>([]);
    const [limit, setLimit] = useState(100);
    const [disable, setDisable] = useState(false);
    const [loading, setLoading] = useState(true);

    const {url} = useRouteMatch();
    const {lpId} = useParams<Props>();
    const teacherEmail = fbAuth.currentUser?.email || ""

    useEffect(() => {
        const getLcList = async () => {
            // call firebase
            //   let lcSnapFb = await getLiveClassesListPerTeacher("lt0Isk6bnkGPMbwCBuiNbw");
            // let lcSnapFb = await getRcList(lpId, limit);
            let lcSnapFb = await getLcListPerTeacher(teacherEmail);

            let lcSnapFbArray: LcRcList[] = [];

            lcSnapFb?.forEach(item => {
                lcSnapFbArray.push(item.val())
            })

            lcSnapFbArray.sort(function (a, b) {
                return a.lcIndex - b.lcIndex;
            });

            console.log(lcSnapFbArray);

            setLcSnap(lcSnapFbArray);
            if (lcSnapFb.numChildren() < 100) {
                setDisable(true)
            }
            setLoading(false);

        }

        getLcList()

        return () => setLcSnap([])
    }, [limit])


    const handleLoadMore = () => {
        setLoading(true)
        setLimit(limit + 100)
    }

    return <Container headerTitle={"Live Class"}>
        <Grid container spacing={2} justify="center" alignItems="center" direction="column"
        >


            {loading ? <SimpleBackdrop openProps={loading}/> : ""}
        </Grid>

        <Grid container spacing={2}>

            {lcSnap ? lcSnap.map((item, index) => (
                <Grid key={index} item xs={12} md={6}>


                    <Card className={classes.root}>

                        <CardActions style={{marginLeft: 'auto', float: 'right'}}>

                            {item.lcStartDateTs < moment.now() ? <Chip label={"Expired"} color={"default"}
                                                                       size={"medium"}/> :
                                <Chip label={"Upcoming"} color={"primary"}
                                      size={"small"}/>}


                            <LongMenu lcId={item.lcId} lpId={item.lpId}/>

                        </CardActions>

                        <CardHeader
                            avatar={
                                <Avatar src={item.lcImage} aria-label={item.lcTitle}>
                                    S
                                </Avatar>
                            }

                            title={item.lcTitle}
                            subheader={<Moment
                                format="LL">{item.lcStartDateTs}</Moment>}>
                        </CardHeader>
                        <Divider/>
                        <CardContent>

                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                {item.lcDesc}
                            </Typography>

                        </CardContent>
                        <CardActions className={classes.withSpacing} disableSpacing key={item.lpId}
                                     style={{marginLeft: 'auto', float: 'right'}}>


                            {/*<Link key={item.lpId} to={`/live-classes/${item.lpId}/${item.lcId}`}>*/}
                            {/*    <Button variant="contained" color={"secondary"} size={"small"}*/}
                            {/*            startIcon={<Class/>}*/}
                            {/*    >View</Button></Link>*/}
                            <Link key={item.lpId} to={`/live-classes/${item.lpId}/${item.lcId}/report`}>
                                <Button variant="contained" color={"secondary"} size={"small"}
                                        startIcon={<ViewHeadlineRounded/>}
                                >Reports</Button></Link>
                        </CardActions>


                    </Card>

                </Grid>

            )) : <CircularProgress color="inherit"/>
            }
            <Grid container justify="center" alignItems="center" direction="column"
            >

                {lcSnap.length > 0 ? <Button fullWidth onClick={handleLoadMore} variant="text"
                                             color="secondary" style={{display: disable ? "block" : "none"}}>Load
                    More</Button> : "Class not available"}


            </Grid>

        </Grid>

    </Container>
}

export default LcListCardView