import React, {FC} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Chip, Typography} from "@material-ui/core";


interface Props {
    openProps: boolean,
    row: any
    handleCloseProps: Function

}

const AlertDialog: FC<Props> = ({row, openProps, handleCloseProps}) => {
    const [open, setOpen] = React.useState(openProps);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        handleCloseProps()

    };

    return (
        <div>

            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{row.lpTitle} <Chip label={row.lpStatus} color={"primary"}
                                                                         size={"small"}/></DialogTitle>
                <DialogContent>

                    <Typography
                        variant={"caption"}>Category</Typography>


                    <DialogContentText> {row.lpCategory}</DialogContentText>
                    <Typography
                        variant={"caption"}>Description</Typography>
                   
                    <DialogContentText> {row.lpDesc}</DialogContentText>


                    <img src={row.lpImage} width={100} height={"auto"} alt={row.lpTitle}/>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        close
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AlertDialog;