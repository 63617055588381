import React, {FC} from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }),
);


interface Props {
    openProps: boolean,

}

const SimpleBackdrop: FC<Props> = ({openProps}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(openProps);
    const handleClose = () => {
        setOpen(false);
    };
   

    return (
        <div>

            <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        </div>
    );
}


export default SimpleBackdrop
