import React, {ChangeEvent, FC, useState} from 'react'
import {useHistory} from 'react-router-dom';
import {Box, Button, CssBaseline, Grid, Link, Paper, TextField, Typography} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import fbAuth, {loginWithEmail} from '../../../../services/fbAuth';
import {User} from '../../../../services/interfaces';
import logoImage from "../../../../assets/logo_bw.png";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="">
                Sols 24/7
            </Link>{' '}

        </Typography>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '100vh',
        },
        image: {
            backgroundImage: 'url(/assets/images/using_sols_education_app.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor:
                theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        },
        paper: {
            margin: theme.spacing(8, 4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
    }));

const Form: FC = () => {
    const classes = useStyles();

    const [form, setForm] = useState<User>({} as User);
    const [alertMsg, setAlertMsg] = useState("");

    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();

    const handleChange = (e: any, input: string) => {
        e.preventDefault();
        const value = e.target.value;
        switch (input) {
            case 'email':
                setForm({...form, email: value});
                break;
            case 'password':
                setForm({...form, password: value});
                break;
        }
    }

    function showAdminUI() {
        history.push('/');
    }

    function showRegularUI() {

        setAlertMsg("Only Teachers and Admins can login.")
        fbAuth.signOut();
        history.push('/login');
    }

    const handleSubmit = async (e: ChangeEvent) => {
        e.preventDefault();
        loginWithEmail(form).then((myUser) => {

            myUser.user?.getIdTokenResult()
                .then((idTokenResult) => {

                    if (!!idTokenResult.claims.role) {
                        showAdminUI();
                    } else {

                        showRegularUI();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });


        }).catch(error => {
            console.log(error);

            if (error) {
                enqueueSnackbar('There is a problem, Please contact SOLS Software Team.', {variant: 'error'});
            }
        })
    }

    return (<Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={false} sm={4} md={7} className={classes.image}/>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>

                    <img alt={"Sols 24/7"} src={logoImage} width={200} height={'auto'} style={{margin: 2}}/>

                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form className={classes.form} noValidate>


                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={e => handleChange(e, 'email')}


                        />

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"

                            onChange={e => handleChange(e, 'password')}

                        />


                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={(e: any) => handleSubmit(e)}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>

                        </Grid>
                        <Box mt={5}>
                            <Copyright/>
                        </Box>
                    </form>
                </div>
            </Grid>
        </Grid>


    )
}


export default Form
