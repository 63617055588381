import firebase from 'firebase/app';

const config = {
    // apiKey: 'AIzaSyBk8Yg5D1G36UpetLQ2cUnBMHUDt1HWFfA',
    // authDomain: 'sols-edu-dev.firebaseapp.com',
    // databaseURL: 'https://sols-edu-dev.firebaseio.com',
    // projectId: 'sols-edu-dev',
    // storageBucket: 'sols-edu-dev.appspot.com',
    // messagingSenderId: '196510697031',
    // appId: '1:196510697031:web:201b16557474454c281362',
    // measurementId: 'G-X8PL45J03N',

    apiKey: "AIzaSyDePugn1z_vIju2ZXRVFGU_4zbeiiYzYkU",
    authDomain: "sols-edu.firebaseapp.com",
    databaseURL: "https://sols-edu.firebaseio.com",
    projectId: "sols-edu",
    storageBucket: "sols-edu.appspot.com",
    messagingSenderId: "808920148175",
    appId: "1:808920148175:web:b30f9be85e0f4a04a5efba",
    measurementId: "G-JH98YWLKHZ"
};

export default firebase.initializeApp(config);
