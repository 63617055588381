import React, {useContext} from 'react'
import {
    Card,
    CardContent,
    CardHeader,
    createStyles,
    Divider,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Theme
} from '@material-ui/core'
import {Link} from "react-router-dom";
import {CallToAction} from "@material-ui/icons";
import {LocalContext} from "../../../services/fbAuth";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        withSpacing: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        root: {
            flexGrow: 1,

        },
        form: {
            '& > *': {
                margin: theme.spacing(2),
                minWidth: '25ch',
            },


        },

        formControl: {
            margin: theme.spacing(1),
            minWidth: 200,
        },
    }),
);

interface Props {
}

const HomeActions = () => {
    const classes = useStyles();
    const {role} = useContext(LocalContext);

    // console.log(role);


    return <Card className={"card"}>

        <CardHeader
            title={"Actions"} avatar={<CallToAction color={"primary"}/>}>
        </CardHeader>
        <Divider/>
        <CardContent>


            <List component="nav" className={classes.root} aria-label="List">
                <Link to={""}>
                    <ListItem button>
                        <ListItemText primary="All Classes"
                                      secondary={"Admins can view all classes as Table list."}/>
                    </ListItem>
                </Link>
                <Divider/> <Link to={""}>
                <ListItem button>
                    <ListItemText primary="Create a Live class"
                                  secondary={"Send Predefined Emails, Cancel Email, or any updates to the current class."}/>
                </ListItem>
            </Link>
                <Divider/> <Link to={""}>
                <ListItem button>
                    <ListItemText primary="Add a user to class" secondary={"Add any other user to a class students"}/>
                </ListItem>
            </Link>
                <Divider/> <Link to={""}>
                <ListItem button>
                    <ListItemText primary="Request an update to this class" secondary={"Request will go to admin."}/>
                </ListItem>
            </Link>
                <Divider/> <Link to={""}>
                <ListItem button>
                    <ListItemText primary="Report a bug" secondary={"Report any bug related to admin app/class/"}/>
                </ListItem>
            </Link>
                <Divider/> <Link to={""}>
                <ListItem button>
                    <ListItemText primary="Add a user to class" secondary={""}/>
                </ListItem>
            </Link>
                <Divider/> <Link to={""}>
                <ListItem button>
                    <ListItemText primary="Add a user to class" secondary={""}/>
                </ListItem>
            </Link>
                <Divider/>

            </List>


        </CardContent>

    </Card>
}

export default HomeActions