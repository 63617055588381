import React, {useContext} from 'react'
import {
    Card,
    CardContent,
    CardHeader,
    createStyles,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    makeStyles,
    Theme
} from '@material-ui/core'
import {Link} from "react-router-dom";
import {Add, CallToAction, Edit, FolderOpen, People, RateReview} from "@material-ui/icons";
import {LocalContext} from "../../../services/fbAuth";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        withSpacing: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        root: {
            flexGrow: 1,

        },
        form: {
            '& > *': {
                margin: theme.spacing(2),
                minWidth: '25ch',
            },


        },

        formControl: {
            margin: theme.spacing(1),
            minWidth: 200,
        },
    }),
);

interface Props {
}

const HomeAdminActions = () => {
    const classes = useStyles();
    const {role} = useContext(LocalContext);

    // console.log(role);


    return <Card className={"card"}>

        <CardHeader
            title={"Quick Links"} avatar={<CallToAction color={"primary"}/>}>
        </CardHeader>
        <Divider/>
        <CardContent>


            <List component="nav" className={classes.root} aria-label="List"
                  subheader={<ListSubheader>Live Classes</ListSubheader>}>
                <Link to={"/live-classes"}>
                    <ListItem button>
                        <ListItemIcon>
                            <FolderOpen/>
                        </ListItemIcon>
                        <ListItemText primary="All Classes"
                        />
                    </ListItem>
                </Link>
                <Divider/> <Link to={""}>
                <ListItem button>
                    <ListItemIcon>
                        <Edit/>
                    </ListItemIcon>
                    <ListItemText primary="Create a Live class"
                    />
                </ListItem>
            </Link>
                <Divider/> <Link to={""}>
                <ListItem button>
                    <ListItemIcon>
                        <Add/>
                    </ListItemIcon>
                    <ListItemText primary="Add a user to class"/>
                </ListItem>
            </Link>
                <Divider/> <Link to={""}>
                <ListItem button>
                    <ListItemIcon>
                        <Edit/>
                    </ListItemIcon>
                    <ListItemText primary="Create a Program"/>
                </ListItem>
            </Link>


            </List>

            <List component="nav" className={classes.root} aria-label="List"
                  subheader={<ListSubheader>Teacher</ListSubheader>}>
                <Link to={""}>
                    <ListItem button>
                        <ListItemIcon>
                            <People/>
                        </ListItemIcon>
                        <ListItemText primary="All Teachers"
                        />
                    </ListItem>
                </Link>
                <Divider/> <Link to={""}>
                <ListItem button>
                    <ListItemIcon>
                        <Edit/>
                    </ListItemIcon>
                    <ListItemText primary="Create New"
                    />
                </ListItem>
            </Link>
                <Divider/> <Link to={""}>
                <ListItem button>
                    <ListItemIcon>
                        <RateReview/>
                    </ListItemIcon>
                    <ListItemText primary="Teachers Ratings"/>
                </ListItem>
            </Link>


            </List>

            <List component="nav" className={classes.root} aria-label="List"
                  subheader={<ListSubheader>Support</ListSubheader>}>
                <Link to={""}>
                    <ListItem button>
                        <ListItemIcon>
                            <FolderOpen/>
                        </ListItemIcon>
                        <ListItemText primary="Supports"
                        />
                    </ListItem>
                </Link>
                <Divider/> <Link to={""}>
                <ListItem button>
                    <ListItemIcon>
                        <Edit/>
                    </ListItemIcon>
                    <ListItemText primary="Create New"
                    />
                </ListItem>
            </Link>


            </List>

            <List component="nav" className={classes.root} aria-label="List"
                  subheader={<ListSubheader>Programs</ListSubheader>}>
                <Link to={""}>
                    <ListItem button>
                        <ListItemIcon>
                            <FolderOpen/>
                        </ListItemIcon>
                        <ListItemText primary="All Programs"
                        />
                    </ListItem>
                </Link>
                <Divider/> <Link to={""}>
                <ListItem button>
                    <ListItemIcon>
                        <Edit/>
                    </ListItemIcon>
                    <ListItemText primary="Create New"
                    />
                </ListItem>
            </Link>


            </List>


            <List component="nav" className={classes.root} aria-label="List"
                  subheader={<ListSubheader>Users</ListSubheader>}>
                <Link to={""}>
                    <ListItem button>
                        <ListItemIcon>
                            <FolderOpen/>
                        </ListItemIcon>
                        <ListItemText primary="All Classes"
                        />
                    </ListItem>
                </Link>
                <Divider/> <Link to={""}>
                <ListItem button>
                    <ListItemIcon>
                        <Edit/>
                    </ListItemIcon>
                    <ListItemText primary="Create a Live class"
                    />
                </ListItem>
            </Link>
                <Divider/> <Link to={""}>
                <ListItem button>
                    <ListItemIcon>
                        <Add/>
                    </ListItemIcon>
                    <ListItemText primary="Add a user to class"/>
                </ListItem>
            </Link>
                <Divider/> <Link to={""}>
                <ListItem button>
                    <ListItemIcon>
                        <Edit/>
                    </ListItemIcon>
                    <ListItemText primary="Create a Program"/>
                </ListItem>
            </Link>


            </List>
        </CardContent>

    </Card>
}

export default HomeAdminActions