import React from 'react'

interface Props {
}

console.log("CreateNewClass");

const LcSingleEdit = () => {
    return <div>

        <div
            className="site-content"

        >
            {/*<LcSingleCreateForm/>*/}
        </div>
    </div>
}

export default LcSingleEdit