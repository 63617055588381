import React, {useContext, useState} from 'react'
import {
    Card,
    CardContent,
    CardHeader,
    createStyles,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Theme
} from '@material-ui/core'
import {Link} from "react-router-dom";
import {CallToAction, Create} from "@material-ui/icons";
import {LocalContext} from "../../../../services/fbAuth";
import shortid from "shortid";
import {useConfirm} from "material-ui-confirm";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        withSpacing: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        root: {
            flexGrow: 1,

        },
        form: {
            '& > *': {
                margin: theme.spacing(2),
                minWidth: '25ch',
            },


        },

        formControl: {
            margin: theme.spacing(1),
            minWidth: 200,
        },
    }),
);

interface Props {
}

const SingleClassActions = () => {
    const classes = useStyles();
    const {role} = useContext(LocalContext);
    const [code, setCode] = useState("ABCD");

    // console.log(role);

    const confirm = useConfirm();
    shortid.characters('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$@');

    let id = shortid.generate()
    const generateShortId = () => {
        //get code from firebase and set it.

        // if the code is not present then generate and setvalue to node

        // fetch the code and show, show a warning that old code will not work

        setCode(id)
        // first

        confirm({
            title: 'Are you sure to update the code?',
            description: 'Previous code will no longer work.',
            confirmationText: 'Yes, I want to change the code.'
        })
            .then(() => { /* ... */

                alert("code updated")
            })
            .catch(() => { /* ... */
            });
    };


    return <>
        <Card className={"card"}>

            <CardHeader
                title={"Actions"} avatar={<CallToAction color={"primary"}/>}>
            </CardHeader>
            <Divider/>
            <CardContent>


                <List component="nav" className={classes.root} aria-label="List">

                    <ListItem>
                        <ListItemText primary="Class code"
                                      secondary={code}/>

                        <ListItemSecondaryAction>
                            {code && <IconButton edge="end" aria-label="comments" onClick={generateShortId}>
                                <Create/>
                            </IconButton>}
                        </ListItemSecondaryAction>
                    </ListItem>


                    <Divider/> <Link to={""}>
                    <ListItem button>
                        <ListItemText primary="Send a reminder email to class"
                                      secondary={"Send Predefined Emails, Cancel Email, or any updates to the current class."}/>
                    </ListItem>


                </Link>
                    <Divider/> <Link to={""}>
                    <ListItem button>
                        <ListItemText primary="Add a user to class"
                                      secondary={"Add any other user to a class students"}/>
                    </ListItem>
                </Link>
                    <Divider/> <Link to={""}>
                    <ListItem button>
                        <ListItemText primary="Request an update to this class"
                                      secondary={"Request will go to admin."}/>
                    </ListItem>
                </Link>
                    <Divider/> <Link to={""}>
                    <ListItem button>
                        <ListItemText primary="Report a bug" secondary={"Report any bug related to admin app/class/"}/>
                    </ListItem>
                </Link>
                    <Divider/> <Link to={""}>
                    <ListItem button>
                        <ListItemText primary="Add a user to class" secondary={""}/>
                    </ListItem>
                </Link>
                    <Divider/> <Link to={""}>
                    <ListItem button>
                        <ListItemText primary="Add a user to class" secondary={""}/>
                    </ListItem>
                </Link>
                    <Divider/>

                </List>


            </CardContent>

        </Card>
    </>
}

export default SingleClassActions