import React, {useContext} from 'react'
import {
    Card,
    CardContent,
    CardHeader,
    createStyles,
    Divider,
    IconButton,
    InputBase,
    makeStyles,
    Paper,
    Theme
} from '@material-ui/core'
import {Notes, PostAdd} from "@material-ui/icons";
import {LocalContext} from "../../../../services/fbAuth";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        withSpacing: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        root: {
            flexGrow: 1,

        },
        form: {
            '& > *': {
                margin: theme.spacing(2),
                minWidth: '25ch',
            },


        },

        formControl: {
            margin: theme.spacing(1),
            minWidth: 200,
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1
        },
        iconButton: {
            padding: 10
        },
        inputPaper: {
            padding: "2px 4px",
            display: "flex",
            alignItems: "center",
            margin: "10px",

        },
    }),
);

interface Props {
}

const SingleClassNotes = () => {
    const classes = useStyles();
    const {role} = useContext(LocalContext);

    // console.log(role);


    return <Card className={"card"}>

        <CardHeader
            title={"Notes"} avatar={<Notes color={"primary"}/>}>
        </CardHeader>
        <Divider/>
        <CardContent>


            <Paper component="form" className={classes.inputPaper}>

                <InputBase
                    className={classes.input}
                    placeholder="Add notes"
                    inputProps={{"aria-label": "Add Post"}}
                />
                <IconButton
                    type="submit"
                    className={classes.iconButton}
                    aria-label="search"
                >
                    <PostAdd/>
                </IconButton>
            </Paper>
        </CardContent>


    </Card>
}

export default SingleClassNotes