import React from 'react';
import Container from "../../containers/Container";

const Users = () => {
    return (
        <Container headerTitle={"Users"}>

            User list coming soon
        </Container>
    );
};

export default Users;
