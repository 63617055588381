import React, {FC, ReactNode, useEffect, useState} from 'react'
import clsx from 'clsx';
import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Link} from 'react-router-dom';
import {Box, Button} from '@material-ui/core';
import fbAuth from "../../services/fbAuth";
import {Assessment, Assignment, ExitToApp, HomeRounded, ListAlt, People} from "@material-ui/icons";
import logoImage from "../../assets/logo_bw.png";

const drawerWidth = 240;

interface SideBarLinks {
    link: string
    title: string
    icon: any

}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexGrow: 1,
        },
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
    }),
);


interface Props {
    children?: ReactNode
    headerTitle?: string
}

const Container: FC<Props> = ({children, headerTitle}) => {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [sideBarLinks, setSideBarLinks] = useState<SideBarLinks[]>([])


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {


        setSideBarLinks(links)


    }, []);


    const links = [
        {
            link: "/",
            title: "Home",
            icon: <HomeRounded/>

        },
        {
            link: "/live-classes/cards",
            title: "My Classes ",
            icon: <ListAlt/>

        },


        {
            link: "/users",
            title: "Users",
            icon: <People/>

        },
        {
            link: "/certificates",
            title: "Certificates",
            icon: <Assignment/>

        },
        {
            link: "/reports",
            title: "Reports",
            icon: <Assessment/>

        },


    ]

    return <div className={classes.root}>
        <CssBaseline/>
        <AppBar
            position="fixed"

            className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
            })}
            // style={{backgroundColor: '#F8BE15', color: '#000000'}}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, open && classes.hide)}
                >
                    <MenuIcon/>
                </IconButton>

                <Typography variant="h6" noWrap className={classes.title}>
                    {headerTitle}
                </Typography>

                <Button startIcon={<ExitToApp/>} color="inherit" onClick={() => fbAuth.signOut()}>

                    {fbAuth.currentUser ? "Logout" : "Login"}
                </Button>
            </Toolbar>
        </AppBar>
        <Drawer

            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
                paper: classes.drawerPaper,
            }}
        >

            <div className={classes.drawerHeader}>
                <img alt={"Sols 24/7"} src={logoImage} width={150} height={'auto'} style={{margin: 2}}/>

                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                </IconButton>
            </div>
            <Divider/>
            <Box component="span" m={1} textAlign={"center"}>

                <Typography variant="subtitle1" color="textPrimary">Welcome, </Typography>

                <Typography variant="subtitle1"
                            color="textPrimary">{fbAuth.currentUser?.displayName}</Typography>
                <Typography variant="caption" color="textSecondary">{fbAuth.currentUser?.email}</Typography>

            </Box>
            <Divider/>
            <List>
                {sideBarLinks.map((item, index) => (
                    <Link to={item.link} key={item.title}>
                        <ListItem button key={item.title}>
                            {/*<ListItemIcon><ChevronRightIcon/></ListItemIcon>*/}
                            <ListItemIcon>{item.icon}</ListItemIcon>

                            <ListItemText primary={item.title}/>

                        </ListItem>
                    </Link>
                ))}
            </List>

        </Drawer>
        <main
            className={clsx(classes.content, {
                [classes.contentShift]: open,
            })}
        >
            <div className={classes.drawerHeader}/>
            {children}
        </main>
    </div>
}

export default Container