import React, {useEffect, useState} from 'react'
import {BrowserRouter as Router} from 'react-router-dom';
import {ThemeProvider, unstable_createMuiStrictModeTheme as createMuiTheme} from '@material-ui/core/styles';
import {SnackbarProvider} from 'notistack';
import app from "./services/firebase";
import {AuthContext, LocalContext} from './services/fbAuth';
import Routes from './Routes';
import {brown, grey} from "@material-ui/core/colors";

import {ConfirmProvider} from 'material-ui-confirm';

const App: React.FC = () => {
    // const [user, setUser] = useState(null);
    const [user, setUser] = useState<any | null>(null);
    const [role, setRole] = useState<number>(0);

    const [pending, setPending] = useState(true);
    useEffect(() => {
        app.auth().onAuthStateChanged((currentUser) => {
            setPending(false);

            currentUser?.getIdTokenResult().then(idTokenResult => {

                const userRole = idTokenResult.claims.role || 0;
                setRole(userRole)
                setUser(currentUser)


            })


        });
    }, []);
    if (pending) {
        return <>Please wait...</>
    }
    return (
        <>
            <Router>
                <AuthContext.Provider value={{user}}>
                    <LocalContext.Provider value={{role}}>
                        <ThemeProvider theme={theme}>
                            <SnackbarProvider maxSnack={3}>
                                <ConfirmProvider>
                                    <Routes/>
                                </ConfirmProvider>
                            </SnackbarProvider>
                        </ThemeProvider>
                    </LocalContext.Provider>
                </AuthContext.Provider>
            </Router>
        </>
    )
}


const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#F8BE15",
            contrastText: brown["900"]
        },
        secondary: {
            main: grey["900"],
            contrastText: grey["50"]
        }


    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 480,
            md: 768,
            lg: 1280,
            xl: 1920,
        }
    }
});

export default App
