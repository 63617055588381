import React, {FC, useContext, useEffect, useState} from 'react'
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    createStyles,
    Divider,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core'
import {Link, useParams} from "react-router-dom";
import Button from "@material-ui/core/Button";
import ClassIcon from "@material-ui/icons/Class";
import {Info} from "@material-ui/icons";
import {LocalContext} from "../../../../services/fbAuth";
import {LcRcList} from "../../../../services/interfaces";
import {getLcDetailsHtml, getLcMeetings, getLcRcList} from "../../../../services/db/liveclasses";
import moment from "moment";

const shortid = require('shortid');

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        withSpacing: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        root: {
            flexGrow: 1,

        },
        form: {
            '& > *': {
                margin: theme.spacing(2),
                minWidth: '25ch',
            },


        },

        formControl: {
            margin: theme.spacing(1),
            minWidth: 200,
        },
    }),
);

interface Props {
    lcId: string
}

const ClassInfo: FC = () => {
    const classes = useStyles();
    const {role} = useContext(LocalContext);

    const [liveClassState, setLiveClassState] = useState<LcRcList>({} as LcRcList);

    const [loading, setLoading] = useState(true);

    const {lcId} = useParams<Props>();

    useEffect(() => {
        const getFbData = async () => {

            let liveClass = await getLcRcList(lcId)
            let liveClassDetails = await getLcDetailsHtml(lcId)
            let lcMeetings = await getLcMeetings(lcId)
            setLiveClassState(liveClass.val())
            setLoading(false);

        }


        getFbData();


    }, [lcId])


    return <Card className={"card"}>

        <CardHeader
            title={"Class Information"}
            avatar={<Info color={"primary"}/>}
            subheader={liveClassState.lcTitle}

        >
        </CardHeader>
        <Divider/>
        <CardContent>
            <Typography variant="body1"
                        color="initial"><span><b>Start Date: </b></span>{moment(liveClassState.lcStartDateTs).format('dddd, MMMM Do YYYY, h:mm:ss a')},</Typography>

            <Divider variant={"middle"}/>
            <Typography variant="body1"
                        color="initial"><span><b>End Date: </b></span>{moment(liveClassState.lcEndDateTs).format('dddd, MMMM Do YYYY, h:mm:ss a')}
            </Typography>

            <Typography variant="body1" color="initial"><span><b>Age: </b></span>{liveClassState.lcAge}</Typography>

            <Typography variant="body1" color="initial"><span><b>Category: </b></span>{liveClassState.lcCategory}
            </Typography>

            <Typography variant="body1" color="initial"><span><b>CourseId: </b></span>{liveClassState.lcCourseId}
            </Typography>
            <Typography variant="body1" color="initial"><span><b>DataA: </b></span>{liveClassState.lcDataA}</Typography>
            <Typography variant="body1" color="initial"><span><b>DataB: </b></span>{liveClassState.lcDataB}</Typography>
            <Typography variant="body1" color="initial"><span><b>Desc: </b></span>{liveClassState.lcDesc}</Typography>
            <Typography variant="body1" color="initial"><span><b>DescHTML: </b></span>{liveClassState.lcDescHTML}
            </Typography>


            <Typography variant="body1" color="initial"><span><b>Id: </b></span>{liveClassState.lcId}</Typography>
            <Typography variant="body1" color="initial"><span><b>Image: </b></span>{liveClassState.lcImage}</Typography>
            <img src={liveClassState.lcImage} alt={liveClassState.lcTitle}/>
            <Typography variant="body1" color="initial"><span><b>Index: </b></span>{liveClassState.lcIndex}</Typography>
            <Typography variant="body1" color="initial"><span><b>IndexAsc: </b></span>{liveClassState.lcIndexAsc}
            </Typography>
            <Typography variant="body1" color="initial"><span><b>Level: </b></span>{liveClassState.lcLevel}</Typography>

            <Typography variant="body1"
                        color="initial"><span><b>Prerequisite: </b></span>{liveClassState.lcPrerequisite}</Typography>

            <Typography variant="body1" color="initial"><span><b>Status: </b></span>{liveClassState.lcStatus}
            </Typography>

            <Typography variant="body1" color="initial"><span><b>TagId: </b></span>{liveClassState.lcTagId}</Typography>

            <Typography variant="body1" color="initial"><span><b>Target Lang: </b></span>{liveClassState.lcTargetLang}
            </Typography>
            <Typography variant="body1" color="initial"><span><b>Title: </b></span>{liveClassState.lcTitle}</Typography>
            <Typography variant="body1" color="initial"><span><b>Type: </b></span>{liveClassState.lcType}</Typography>

            <Typography variant="body1" color="initial"><span><b>Program Title: </b></span>{liveClassState.programTitle}
            </Typography>
            <Typography variant="body1" color="initial"><span><b>Support Title: </b></span>{liveClassState.supportTitle}
            </Typography>
            <Typography variant="body1" color="initial"><span><b>Target Group: </b></span>{liveClassState.targetGroup}
            </Typography>
            <Typography variant="body1" color="initial"><span><b>Teacher Email: </b></span>{liveClassState.teacherEmail}
            </Typography>

            <Typography variant="body1" color="initial"><span><b>Teacher Name: </b></span>{liveClassState.teacherName}
            </Typography>
            <Typography variant="body1" color="initial"><span><b>Total Slots: </b></span>{liveClassState.totalSlots}
            </Typography>


        </CardContent>

        <CardActions className={`${classes.withSpacing}`}>


            {role <= 15 ?

                <Link to="/live-classes"> <Button startIcon={<ClassIcon/>} variant="contained"
                                                  color="primary">Edit</Button></Link>
                : ""}
        </CardActions>
    </Card>
}

export default ClassInfo