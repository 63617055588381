import db from './_index';
import {LiveClass} from '../interfaces';

export async function createBooking(user: any, liveClass: LiveClass) {
    const {
        lcId,
        lcTitle,
        teacherName,
        lcStartDateTs,
        lcEndDateTs,
        lcMeetingId,
        lcIndex,
        lcIndexAsc,
        lcImage
    } = liveClass;
    let bookingRef = db.ref(`/usersBookings/${user?.uid}/${lcId}`);

    const bookingData = {
        lcTitle,
        teacherName,
        lcStartDateTs,
        lcEndDateTs,
        lcMeetingId,
        lcIndex,
        lcIndexAsc,
        lcImage,
        userEmail: user.email,
        userName: user.displayName
    }

    return await bookingRef.set(bookingData)
}

export async function getBookings(uid: string, lcid: string = '') {
    return await db.ref(`/usersBookings/${uid}/${lcid}`).once('value');
}

export async function cancelBooking(uid: string, lcid: string) {
    return await db.ref(`/usersBookings/${uid}/${lcid}`).remove();
}

export async function getAllBookings() {
    return await db.ref(`/usersBookings/`)
        .once('value');
}


export async function getLcBookings() {
    return await db.ref(`/lcBookings/english/`)
        .orderByKey()
        .limitToFirst(1)
        .once('value');
}