import React, {useContext} from 'react'
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    createStyles,
    Divider,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core'
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import ClassIcon from "@material-ui/icons/Class";
import {Notifications} from "@material-ui/icons";
import {LocalContext} from "../../../../services/fbAuth";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        withSpacing: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        root: {
            flexGrow: 1,

        },
        form: {
            '& > *': {
                margin: theme.spacing(2),
                minWidth: '25ch',
            },


        },

        formControl: {
            margin: theme.spacing(1),
            minWidth: 200,
        },
    }),
);

interface Props {
}

const SingleClassMaterial = () => {
    const classes = useStyles();
    const {role} = useContext(LocalContext);

    // console.log(role);


    return <Card className={"card"}>

        <CardHeader
            title={"Class Material"} avatar={<Notifications color={"primary"}/>}>
        </CardHeader>
        <Divider/>
        <CardContent>
            <Typography>

            </Typography>


        </CardContent>

        <CardActions className={`${classes.withSpacing}`}>

            {/*{customClaim < 10 &&*/}
            {role < 15 ? <Link to="/live-classes"> <Button startIcon={<ClassIcon/>} variant="contained"
                                                           color="primary">All Classes</Button></Link> : ""}

            <Link to="/live-classes"> <Button startIcon={<ClassIcon/>} variant="contained"
                                              color="default">My Classes</Button></Link>

        </CardActions>
    </Card>
}

export default SingleClassMaterial