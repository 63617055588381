import React, {FC} from 'react'
import {useRouteMatch} from 'react-router-dom';
import Container from '../../containers/Container';
import LcReportSummary from "./LcReportSummary";


interface Props {
}

const LiveClasses: FC = () => {
    const {path, url} = useRouteMatch();
    console.log(path);

    return <Container headerTitle={"Report"}>
        <LcReportSummary/>

    </Container>
}

export default LiveClasses