import * as React from 'react';
import {FC} from 'react';
import {DataGrid, GridColDef, GridToolbar} from '@material-ui/data-grid';
import {LcRcList} from "../services/interfaces";


interface Props {
    rowsData: LcRcList[],
    columnsData: GridColDef[],
}


const LcListDataTable: FC<Props> = ({rowsData, columnsData}) => {
    return (
        <div style={{height: 400, width: '100%'}}>

            <DataGrid
                rows={rowsData}
                columns={columnsData}
                density={"compact"}
                components={{
                    Toolbar: GridToolbar,

                }}
                checkboxSelection={true}
                autoHeight={true}
                disableExtendRowFullWidth={false}

            />
        </div>
    );
}
export default LcListDataTable;