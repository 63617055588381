import React, {FC, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Grid, Paper} from '@material-ui/core';
import {useHistory, useParams, useRouteMatch} from 'react-router-dom';
import {getRcListAll} from '../../../services/db/liveclasses';
import {LcRcList} from '../../../services/interfaces';
import Container from "../../../containers/Container";
import ViewListIcon from '@material-ui/icons/ViewList';
import {GridApi, GridCellValue, GridColDef, GridValueFormatterParams} from "@material-ui/data-grid";
import moment from "moment";
import SimpleBackdrop from "../../../components/UiElements/BackDrop";
import LcListDataTable from "../../../datatables/LcListDataTable";
import {Visibility} from "@material-ui/icons";

const useStyles = makeStyles({

    withSpacing: {
        '& > *': {
            margin: 1,
        },
    },
    root: {
        minWidth: 275,
    },

    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});


interface Props {
    lpId: string
}

const LcList: FC = () => {

    const {path, url} = useRouteMatch();
    console.log(path);
    const history = useHistory();
    const classes = useStyles();
    const [lcSnap, setLcSnap] = useState<LcRcList[]>([]);
    const [loading, setLoading] = useState(true);
    const [singleRow, setSingleRow] = useState([] as any);
    const [showAlert, setShowAlert] = useState(false);
    const {lpId} = useParams<Props>();
    const columnsData: GridColDef[] = [
        {field: 'lcTitle', headerName: 'lcTitle', width: 300, cellClassName: "tableCell"},
        {
            field: "info",
            headerName: "Info",
            sortable: false,
            width: 100,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClick = () => {
                    const api: GridApi = params.api;
                    const fields = api
                        .getAllColumns()
                        .map((c) => c.field)
                        .filter((c) => c !== "__check__" && !!c);
                    const thisRow: Record<string, GridCellValue> = {};

                    fields.forEach((f) => {
                        thisRow[f] = params.getValue(f);
                    });
                    setSingleRow(thisRow);

                    const link = `/live-classes/${thisRow.id}/view`;

                    return history.push(link)


                };


                return <Button startIcon={<Visibility/>} onClick={onClick} variant={"text"} color={"secondary"}
                               size={"small"}/>;
            }
        },
        {
            field: "rec",
            headerName: "Recurrence",
            sortable: false,
            width: 100,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClick = () => {
                    const api: GridApi = params.api;
                    const fields = api
                        .getAllColumns()
                        .map((c) => c.field)
                        .filter((c) => c !== "__check__" && !!c);
                    const thisRow: Record<string, GridCellValue> = {};

                    fields.forEach((f) => {
                        thisRow[f] = params.getValue(f);
                    });
                    const link = `/live-classes/${lpId}/${thisRow.id}/report`;

                    return history.push(link)
                };

                return <Button startIcon={<ViewListIcon/>} onClick={onClick} variant={"text"} color={"primary"}
                               size={"small"}/>;
            }
        },


        {
            field: 'lcStartDateTs', headerName: 'Start Date', width: 300, type: "dateTime",
            valueFormatter: (params: GridValueFormatterParams) =>
                moment(Number(params.value)).format("dddd, MMMM Do YYYY, h:mm:ss a")
        },
        {
            field: 'lcEndDateTs', headerName: 'End Date', width: 300, type: "dateTime",
            valueFormatter: (params: GridValueFormatterParams) =>
                moment(Number(params.value)).format("dddd, MMMM Do YYYY, h:mm:ss a")
        },

        {field: 'id', headerName: 'id', width: 300},
        {field: 'teacherName', headerName: 'Teacher', width: 300},
        {field: 'programTitle', headerName: 'Program', width: 300},
        {field: 'supportTitle', headerName: 'Support', width: 300},
        {field: 'lcCategory', headerName: 'Category', width: 300},
        {field: 'lcStatus', headerName: 'Status', width: 300},
        {field: 'lcAge', headerName: 'Age', width: 300},
        {field: 'totalSlots', headerName: 'Total Slots', width: 300},
        {field: 'lcCourseId', headerName: 'CourseId', width: 300},
        {field: 'lcDataA', headerName: 'DataA', width: 300},
        {field: 'lcDataB', headerName: 'DataB', width: 300},
        {field: 'lcDesc', headerName: 'Desc', width: 300},
        {field: 'targetGroup', headerName: 'Target Group', width: 300},
        {field: 'teacherEmail', headerName: 'Teacher Email', width: 300},
        {field: 'lcDescHTML', headerName: 'Desc HTML', width: 300},
        {field: 'lcPrerequisite', headerName: 'Prerequisite', width: 300},
        {field: 'lcGroupId', headerName: 'Group Id', width: 300},
        {field: 'lcId', headerName: 'Id', width: 300},
        {field: 'lcImage', headerName: 'Image', width: 300},
        {field: 'lcIndex', headerName: 'Index', width: 300},
        {field: 'lcIndexAsc', headerName: 'IndexAsc', width: 300},
        {field: 'lcLevel', headerName: 'Level', width: 300},
        {field: 'lcLevelId', headerName: 'LevelId', width: 300},
        {field: 'lcProgramId', headerName: 'Program Id', width: 300},
        {field: 'lcSupportId', headerName: 'Support Id', width: 300},
        {field: 'lcAgeGroupId', headerName: 'Age Group Id', width: 300},
        {field: 'lcTagId', headerName: 'Tag Id', width: 300},
        {field: 'lcCategoryId', headerName: 'Category Id', width: 300},
        {field: 'lcTargetGroupId', headerName: 'Target Group Id', width: 300},
        {field: 'lcTargetLang', headerName: 'Target Lang', width: 300},
        {field: 'lcType', headerName: 'Type', width: 300},
        {field: 'lcTypeId', headerName: 'Type Id', width: 300},
        {field: 'lpId', headerName: 'Parent Id', width: 300},
        {field: 'teacherId', headerName: 'Teacher Id', width: 300},


    ];

    useEffect(() => {
        const callLiveClassesList = async () => {
            // call firebase
            //   let lcSnapFb = await getLiveClassesListPerTeacher("lt0Isk6bnkGPMbwCBuiNbw");
            let lcSnapFb = await getRcListAll(lpId);

            let lcSnapFbArray: LcRcList[] = [];
            let optionsArray = [];

            lcSnapFb?.forEach(item => {
                let obj = Object.assign(item.val(), {"id": item.key});
                lcSnapFbArray.push(obj)


            })


            setLcSnap(lcSnapFbArray);


            setLoading(false);
        }


        callLiveClassesList()

        return () => setLcSnap([])
    }, [])

    const handleClose = () => {
        setShowAlert(false)

    }


    return <Container headerTitle={"Live Class List"}>

        <Grid container spacing={2} justify="center" alignItems="center" direction="column"
        >


            {loading ? <SimpleBackdrop openProps={loading}/> : ""}
        </Grid>


        <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
                <Paper>
                    {lcSnap.length > 0 ? "" : "Class not available"}
                    <LcListDataTable rowsData={lcSnap} columnsData={columnsData}/>
                </Paper>
            </Grid>

        </Grid>


    </Container>
}

export default LcList