import React, {FC, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import Container from '../../containers/Container'
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {getLcRcList, getLiveClassBookings} from '../../services/db/liveclasses';
import {LcRcList, LiveClassBookings, LiveClassTable} from '../../services/interfaces';
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Theme, Typography} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import {GridColDef} from "@material-ui/data-grid";
import moment from "moment";
import DataTable from "../../datatables/DataTable";
import shortUUID from "short-uuid";
import SimpleBackdrop from "../../components/UiElements/BackDrop";


let S = require('string');

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: '25ch',
        },
    }),
);


const LcReportSingle: FC = () => {
    const classes = useStyles();
    const [bookingList, setBookingList] = useState<LiveClassBookings[]>([]);
    const [liveClassState, setLiveClassState] = useState<LcRcList>({} as LcRcList);
    const [lcRowState, setLcRowState] = useState<LiveClassTable[]>([]);

    const [loading, setLoading] = useState(true);

    const {lcId} = useParams<Props>();

    let rowsTempData: LiveClassTable[] = [];
    let lcSnapFbArray: LiveClassBookings[] = [];

    useEffect(() => {
        const callLiveClassBookings = async () => {

            let lcBookingList = await getLiveClassBookings(lcId);
            let liveClass = await getLcRcList(lcId)
            setLiveClassState(liveClass.val())
            lcBookingList?.forEach(item => {
                // let obj = Object.assign(item.val(), {"id": item.key});
                let id: string = item.key || String(shortUUID())

                lcSnapFbArray.push(item.val())

                let bObj: LiveClassTable = {
                    id: id,
                    userEmail: item.val().userEmail,
                    userName: item.val().userName !== "null" ? S(item.val().userName).capitalize().s : "No Name",
                    bookingTs: moment(item.val().bookingTs).format('LL')
                }

                rowsTempData.push(bObj)
            })


            // combined both datas

            setBookingList(lcSnapFbArray);
            setLcRowState(rowsTempData)
            setLoading(false);

        }


        callLiveClassBookings();

        return () => setBookingList([])
    }, [lcId])

    const columnsData: GridColDef[] = [
        {field: 'userName', headerName: 'Name', width: 250},
        {field: 'userEmail', headerName: 'Email', width: 300},

        {
            field: 'bookingTs',
            headerName: 'Booking Date',
            width: 300,
        },


    ];


    return <Container headerTitle={"Report"}>
        {loading ? <SimpleBackdrop openProps={loading}/> : ""}
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>


                <Accordion variant={"outlined"}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="panel0a-content"
                        id="panel0a-header"
                    >

                        <Typography variant={"h6"}>
                            {S(lcId.split("-").join(" ")).capitalize().s}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            <p>{liveClassState.lcDesc}</p>

                            <Link to={"/live-classes/" + liveClassState.lpId + "/" + liveClassState.lcId + "/view"}>
                                <Button variant={"contained"}>View Class</Button>
                            </Link>
                        </Box>
                    </AccordionDetails>
                </Accordion>

            </Grid>


            <Grid item xs={12} md={12}>


                <Accordion variant={"outlined"}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography> Total
                            Students: {(bookingList.length).toString()}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {bookingList.map(row => ` ${row.userEmail},`)}
                        </Typography>
                    </AccordionDetails>
                </Accordion>


                {/*<TextField*/}
                {/*    id="outlined-multiline-static"*/}
                {/*    multiline*/}
                {/*    rows={4}*/}
                {/*    defaultValue={bookingList.map(row => ` ${row.userEmail}`)}*/}
                {/*    variant="outlined"*/}
                {/*    style={{width: '100%'}}*/}

                {/*/>*/}

                {/*<Alert severity="info">{bookingList.map(row => `${row.userEmail}`)}</Alert>*/}
            </Grid>
        </Grid>


        <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
                <Paper variant={"outlined"}>
                    <DataTable rowsData={lcRowState} columnsData={columnsData}/>
                </Paper>
            </Grid>

        </Grid>


    </Container>
}

interface Props {
    lcId: string
}

export default LcReportSingle