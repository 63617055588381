import {createContext} from 'react';
import firebase from 'firebase/app';
import app from './firebase';
import 'firebase/auth';
import {User} from './interfaces';


const fbAuth = app.auth();

export const AuthContext = createContext({user: null});
export const LocalContext = createContext({role: 0});

export async function loginWithEmail(form: User) {
    const {email, password} = form;
    return await firebase.auth().signInWithEmailAndPassword(email, password);
}

export function signOut() {
    fbAuth.signOut();
}

export const currentUser = fbAuth.currentUser;

// export const customClaim = () => currentUser?.getIdTokenResult()
//     .then((idTokenResult) => {
//         // Confirm the user is an Admin.
//         // const role: string = idTokenResult.claims.role
//         // console.log(idTokenResult.claims)
//         // console.log(role)
//
//
//         if (!!idTokenResult.claims.role) {
//             // Show admin UI.
//             return idTokenResult.claims.role as string
//         } else {
//             // Show regular user UI.
//             return 100
//         }
//
//     })
//     .catch((error) => {
//         console.log(error);
//     });

export default fbAuth;
