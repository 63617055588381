import * as React from 'react';
import {DataGrid, GridColDef, GridValueGetterParams} from '@material-ui/data-grid';
import {FC} from "react";
import {LcBookingData, LiveClassTable} from "../services/interfaces";


interface Props {
    rowsData: LcBookingData[],
    columnsData: GridColDef[],
}

const UserDataTable: FC<Props> = ({rowsData, columnsData}) => {
    return (
        <div style={{height: 400, width: '100%'}}>

            <DataGrid
                rows={rowsData}
                columns={columnsData}


                density={"compact"}

            />
        </div>
    );
}
export default UserDataTable;